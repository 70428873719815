import React, { useState } from 'react';
import Modal from 'react-modal';
import './AskQuestionModal.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        maxWidth: '400px',
        maxHeight: '80vh'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: '20',
    }
};

const AskQuestionModal = ({ onSubmit }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [question, setQuestion] = useState('');
    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!question) {
            return;
        }

        closeModal();
        onSubmit(question);
        setQuestion('');
        setShowSuccessMessage(true);

        setTimeout(() => {
            setShowSuccessMessage(false);
        }, 3000);
    }

    return (
        <div className='d-flex align-items-center flex-column'>
            <div className={`ask-question-modal__success-message ${showSuccessMessage ? 'ask-question-modal__success-message--show' : ''}`}>
                <p>Thanks for the question.</p>
            </div>
            <button className='btn btn-pink' onClick={openModal}>Ask a Question</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Ask a question"
                className="modal-dialog"
                style={customStyles}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ask a question</h5>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="mb-3">
                                <textarea
                                    value={question}
                                    onChange={handleQuestionChange}
                                    className="form-control"
                                    placeholder="Type your question here"
                                    required
                                    rows={4}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-pink">Submit</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default AskQuestionModal;