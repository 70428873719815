import React, { Component } from 'react';

import UserLogin from '../user-login/UserLogin';
import AnswerChoice from '../answer-choice/AnswerChoice';
import Header from '../header/Header';
import UserStatus from '../user-status/UserStatus';
import AskQuestionModal from '../ask-question/AskQuestionModel';

export default class UserRoute extends Component {
    constructor(props) {
        super(props);

        //let connection = null;

        this.connection = null;

        this.questions = [];
        this.connectionId = null;

        this.state = {
            isLoggedIn: false,
            firstName: null,
            lastName: null,
            email: null,
            consent: false,
            initials: null,
            question: null,
            finished: false,
            started: false,
            showAskQuestionButton: window.askQuestionEnabled
        }
    }

    connect = () => {
        const signalR = window.signalR;
        const url = window.location.hostname === 'localhost' ? 'https://localhost:44397/eventHub' : '/eventHub';
        const log = console.log;
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(signalR.LogLevel.Information)
            .build();

        const self = this;

        async function start() {
            try {
                await self.connection.start();
                console.log("SignalR Connected.", self.connection, self.connection.connection.connectionId);
                console.log('Logging in ', self.state.firstName, self.state.lastName, self.state.email, self.state.consent);
                self.connection.invoke('sendUserJoined', self.state.firstName, self.state.lastName, self.state.email, self.state.consent);
                self.setState({
                    isLoggedIn: true
                });
            } catch (err) {
                console.log(err);
                setTimeout(start, 5000);
            }
        };

        this.connection.on('broadcastEventStarted', (serverId) => {
            log("broadcastEventStarted: " + serverId);
            this.connection.invoke('sendUserJoined', this.state.firstName, this.state.lastName);
        });

        this.connection.on('broadcastPolls', (polls) => {
            log(polls);
            this.questions = polls;
        });

        this.connection.on('broadcastEventEnd', () => {
            log("broadcastEventEnd");
            this.setState({
                question: null,
                finished: true
            });
        });

        this.connection.on('broadcastQuestion', (questionIdx) => {
            this.setState({
                question: this.questions[questionIdx],
                started: true,
                finished: false
            });
        });

        this.connection.onclose(async () => {
            await start.bind(this)();
        });
      
        start.bind(this)();
    }

    handleUserLogin = (firstName, lastName, email, consent) => {
        //console.log('login'.toUpperCase.substring(), firstName, lastName);

        this.setState({
            firstName: firstName,
            lastName: lastName,
            initials: firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase(),
            email: email,
            consent: consent
        }, () => {
            this.connect();
        });
    }

    handleAnswerSelected = (index) => {
        console.log('answer', index);
        this.connection.invoke('sendAnswer', index, this.state.initials);
        this.setState({
            question: null
        });
    }

    handleAskQuestionSubmitted = (question) => {
        console.log('question submitted', question);

        this.connection.invoke('askQuestion', question, this.state.firstName, this.state.lastName);
    }

    render() {
        return (
          <div className="container">
            <Header />
            {!this.state.isLoggedIn &&
            <UserLogin onSubmit={this.handleUserLogin} />
            }
            {this.state.isLoggedIn && this.state.question &&
            <AnswerChoice question={this.state.question} onAnswerSelected={this.handleAnswerSelected} />
            }
            {this.state.isLoggedIn && this.state.started && !this.state.finished && !this.state.question &&
                <UserStatus message="Answer submitted, waiting for next question..."/>
            }
            {this.state.isLoggedIn && this.state.finished &&
                <UserStatus message="Thank you"/>
            }
            {this.state.isLoggedIn && !this.state.started &&
                <UserStatus message="Waiting for quiz to start..."/>
            }
            {this.state.isLoggedIn && (!this.state.started || this.state.finished) && this.state.showAskQuestionButton &&
                <AskQuestionModal onSubmit={this.handleAskQuestionSubmitted} />
            }
          </div>
        );
      }
}