import './Header.scss';

const Header = () => {
  return (
    <header className="header pt-1 pt-md-3 text-center">
        TEAM LEWIS <span className='text-highlight'>QUIZ</span>
    </header>
  );
}

export default Header;
