import './Header.scss';

const WelcomeHeader = () => {
  return (
    <header className="header header--welcome pt-3 pt-md-3 text-center">
        WELCOME TO THE<br />TEAM LEWIS <span className='text-highlight'>QUIZ</span>
    </header>
  );
}

export default WelcomeHeader;
