import './QuestionsList.scss';

const QuestionsList = ({questions}) => {
  return (
    <div className="questions-list">
        {questions.map((question, index) => {
            return (
                <div key={index} className="questions-list__question">
                    <blockquote className='questions-list__question-text'>
                      {question.question}
                      <cite className='questions-list__question-author'>{question.firstName} {question.lastName}</cite>
                    </blockquote>
                    
                </div>
            );
        })}
    </div>
  );
}

export default QuestionsList;
