import './UserStatus.scss';

const UserStatus = ({message}) => {
  return (
    <p className="mt-5 text-center user-status">
        {message}
    </p>
  );
}

export default UserStatus;
