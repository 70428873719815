import './AnswerChoice.scss';

const AnswerChoice = ({question, onAnswerSelected}) => {
    console.log('answer choice', question);

  return (
    <div className="answer-choice mt-3 mt-md-5">
        <p className='answer-choice__question mb-5'>
            {question.question}
        </p>

        <div className='container answer-choice__choices'>
            <div className='row mb-4'>
                {question.answers.length > 0 &&
                <div className='col'>
                    <div className='answer-choice__button-container'>
                        <button onClick={() => onAnswerSelected(0)} type='button'>{question.answers[0].answer}</button>
                    </div>
                </div>
                }
                {question.answers.length > 1 &&
                <div className='col'>
                    <div className='answer-choice__button-container'>
                        <button onClick={() => onAnswerSelected(1)} type='button'>{question.answers[1].answer}</button>
                    </div>
                </div>
                }
            </div>
            <div className='row'>
                {question.answers.length > 2 &&
                <div className='col'>
                    <div className='answer-choice__button-container'>
                        <button onClick={() => onAnswerSelected(2)} type='button'>{question.answers[2].answer}</button>
                    </div>
                </div>
                }
                {question.answers.length > 3 &&
                <div className='col'>
                    <div className='answer-choice__button-container'>
                        <button onClick={() => onAnswerSelected(3)} type='button'>{question.answers[3].answer}</button>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
  );
}

export default AnswerChoice;
