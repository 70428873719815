import React, { Component } from 'react';
import WelcomeHeader from '../header/WelcomeHeader';
import qr from '../images/qr.png';
import './Welcome.scss';

export default class Welcome extends Component {
  render() {
    return (
      <div className="welcome">
        <WelcomeHeader />
        <span className='welcome__player-count'>{this.props.players}</span>
        <p className='mt-3'>Get your phones at the ready.</p>
        <p className='mb-3'>Get involved. Scan QR-code or register at chemistry.teamlewis.com</p>

        <img className='mt-2' src={qr} alt="chemistry.teamlewis.com" />

        <button className='btn btn-pink btn-action' onClick={this.props.onStart}>Start</button>
      </div>
    );
  }
}