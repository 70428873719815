import React, { Component } from 'react';
import QuestionResult from '../question-result/QuestionResult';
import Welcome from '../welcome/Welcome';
import EventEnded from '../event-ended/EventEnded';
import Header from '../header/Header';

const modes = {
    waitingToStart: 0,
    eventInProgress: 1,
    finished: 2
}

class AdminRoute extends Component {
    constructor(props) {
        super(props);

        this.audio = null;
        this.initialised = false;
        this.connection = null;

        this.addBallRefs = [];
        this.addBallRefs.push(React.createRef(null));
        this.addBallRefs.push(React.createRef(null));
        this.addBallRefs.push(React.createRef(null));
        this.addBallRefs.push(React.createRef(null));

        this.players = 0;

        this.answers = {
            total: 0,
            answer0: 0,
            answer1: 0,
            answer2: 0,
            answer3: 0
        }

        this.state = {
            mode: modes.waitingToStart,
            hideBuckets: true,
            questionIndex: -1,
            players: 0,
            questions: window.questions
        };
    }

    componentDidMount() {
        this.initConnection();

        if (document.body.classList.contains('theme-millionaire')) {
            this.audio = new Audio('/assets/sounds/millionaire/welcome.mp3');

            try {
                this.audio.play();
            } catch {

            }
        }
    }

    initConnection() {
        if (this.initialised) {
            return;
        }

        this.initialised = true;
        console.log('connecting');
        const signalR = window.signalR;
        const log = console.log;
        const url = window.location.hostname === 'localhost' ? 'https://localhost:44397/eventHub' : '/eventHub';
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(signalR.LogLevel.Information)
            .build();

        const self = this;

        async function start() {
            try {
                await self.connection.start();
                console.log("SignalR Connected.", self.connection, self.connection.connection.connectionId);

                self.connection.invoke('sendExistingServerCheck');
            } catch (err) {
                console.log(err);
                setTimeout(start, 5000);
            }
        };

        this.connection.on('broadcastUserJoined', (userId, firstName, lastName, initials) => {
            log("broadcastUserJoined: " + firstName + ", " + lastName + ", " + initials);

            this.connection.invoke('sendPolls', userId, this.state.questions);

            this.players++;

            this.setState({
                players: this.players
            });

            if (this.state.questionIndex === -1) {
                // notify user joined
                console.log('user joined', initials);
            } else {
                this.connection.invoke('sendQuestion', userId, this.state.questionIndex);
            }
        });

        this.connection.on('broadcastQuestion', function (pollIdx, questionIdx) {
            log("broadcastQuestion: " + pollIdx + ", " + questionIdx);
        });

        this.connection.on('broadcastAnswer', (answerIndex, initials) => {
            //console.log('answer index', answerIndex, initials);

            this.addBallRefs[answerIndex].current(initials);

            this.answers.total++;

            switch (answerIndex) {
                case 0:
                    this.answers.answer0++;
                    break;
                case 1:
                    this.answers.answer1++;
                    break;
                case 2:
                    this.answers.answer2++;
                    break;
                case 3:
                    this.answers.answer3++;
                    break;
                default:
                    break;
            }

            let questions = this.state.questions;
            let question = questions[this.state.questionIndex];
            if (question.answers.length > 0) {
                question.answers[0].percent = (this.answers.answer0 / this.answers.total * 100).toFixed(0);
            }
            if (question.answers.length > 1) {
                question.answers[1].percent = (this.answers.answer1 / this.answers.total * 100).toFixed(0);
            }
            if (question.answers.length > 2) {
                question.answers[2].percent = (this.answers.answer2 / this.answers.total * 100).toFixed(0);
            }
            if (question.answers.length > 3) {
                question.answers[3].percent = (this.answers.answer3 / this.answers.total * 100).toFixed(0);
            }

            this.setState({
                questions: questions
            });
        });

        this.connection.on('broadcastEventEnd', () => {
            log("broadcastEventEnd");
            this.setState({
                mode: modes.finished
            });
        });

        this.connection.on('broadcastBounce', function () {
            window.location = "/";
        });

        this.connection.onclose(async () => {
            await start();
        });

        start();
    }

    handleStartEvent() {
        this.connection.invoke('sendQuestion', "", 0);
        this.setState({
            mode: modes.eventInProgress,
            questionIndex: 0,
        }, () => {
            setTimeout(() => {
                this.setState({
                    hideBuckets: false
                });
            }, 400);
        });

        if (this.audio) {
            this.audio.pause();

            if (document.body.classList.contains('theme-millionaire')) {
                this.audio = new Audio('/assets/sounds/millionaire/lets-play.mp3');
                try {
                    this.audio.play();
                } catch {
                    
                }
            }
        }
    }

    handleNextQuestion() {
        if (this.state.questionIndex < this.state.questions.length - 1) {
            this.answers.answer0 = 0;
            this.answers.answer1 = 0;
            this.answers.answer2 = 0;
            this.answers.answer3 = 0;
            this.answers.total = 0;

            this.connection.invoke('sendQuestion', "", this.state.questionIndex + 1);
            this.setState({
                hideBuckets: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        questionIndex: this.state.questionIndex + 1
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                hideBuckets: false
                            });
                        }, 100);
                    });
                }, 700);
            });
            
        } else {
            this.setState({
                mode: modes.finished
            });

            this.connection.invoke('sendEventEnd');
        }
    }

    render() {
        return (
            <div className="App">
                {this.state.mode === modes.waitingToStart &&
                    <Welcome onStart={this.handleStartEvent.bind(this)} players={this.state.players} />
                }
                {this.state.mode !== modes.waitingToStart &&
                    <Header />
                }
                {this.state.mode === modes.eventInProgress &&
                    <QuestionResult disabled={this.state.hideBuckets} addBallRefs={this.addBallRefs} onNext={this.handleNextQuestion.bind(this)} question={this.state.questions[this.state.questionIndex]} />
                }
                {this.state.mode === modes.finished &&
                    <EventEnded />
                }
            </div>
        );
    }
}

export default AdminRoute;
