import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";
import './App.scss';
import AdminRoute from './admin-route/AdminRoute';
import UserRoute from './user-route/UserRoute';
import QuestionsListRoute from './questions-list-route/QuestionsListRoute';

// const mode = {
//   user: 'user',
//   admin: 'admin'
// }

class App extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<UserRoute />} />
        <Route path="questions-list" element={<QuestionsListRoute />} />
        <Route path=":eventId" element={<AdminRoute />} />
      </Routes>
    );
  }
}

export default App;
