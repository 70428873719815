import React, { Component } from 'react';
import Ball0 from '../images/balls/0.png';
import Ball1 from '../images/balls/1.png';
import Ball2 from '../images/balls/2.png';

import './AnswerBucket.scss';

// const userState = {
//     wai
// }

const totalImages = 3;
//const balls = [];

const getRandomArbitrary = (min, max) => {
    return Math.random() * (max - min) + min;
}

class Ball {
    constructor(x, y, radius, e, mass, text) {
        this.position = {x: x, y: y}; //m
        this.velocity = {x: -1, y: 0}; // m/s
        this.e = -e; // has no units
        this.mass = mass; //kg
        this.radius = radius; //m
        this.text = text; 
        this.area = (Math.PI * radius * radius) / 10000; //m^2
        this.rotation = 0;
        this.imageIndex = Math.floor(getRandomArbitrary(0, totalImages));
    }
}

const images = [];

export default class AnswerBucket extends Component {
    constructor(props) {
        super(props);

        this.balls = [];
        this.maxBalls = 100;
        this.initialised = false;
        this.width = 200;
        this.height = 200;
        this.gravity = 1;
        this.timer = null;
        this.ctx = null;
        this.props.addBall.current = this.addBall;
        
        this.loadBallImages(Ball0);
        this.loadBallImages(Ball1);
        this.loadBallImages(Ball2);

        this.canvasRef = React.createRef();
    }

    loadBallImages(src) {
        let image = new Image();
        image.src = src;
        images.push(image);
    }

    addBall = (text) => {
        
        if (this.balls.length >= this.maxBalls) {
            return;
        }

        console.log('adding ball', text);

        const startX = this.width / 2 + getRandomArbitrary(-25, 25);
        this.balls.push(new Ball(startX, 0, 15, 0.7, 10, text));

        this.gravity = Math.max(0.01, 3 - (this.balls.length / this.maxBalls * 3));
    }

    componentDidMount() {
        if (this.initialised) {
            return;
        }

        this.initialised = true;
        console.log('mounted');
        this.init();        
    }

    componentDidUpdate(prevProps) {
        if (this.props.disabled && this.props.disabled !== prevProps.disabled) {
            console.log('clearing');
            this.balls = [];//this.balls.splice(0, this.balls.length);
            this.ctx.clearRect(0, 0, this.width, this.height);
        }
      }

    componentWillUnmount() {
        console.log('clearing on unmount');
        this.ctx.clearRect(0, 0, this.width, this.height);
        //clearInterval(this.timer);
    }

    init() {
        var self = this;
        var canvas = null;
        var fps = 1/60; //60 FPS
        var dt = fps * 1000; //ms 
        var mouse = {x: 0, y:0, isDown: false};
        var ag = 9.81; //m/s^2 acceleration due to gravity on earth = 9.81 m/s^2. 
        
        var density = 200;
        var drag = 0.47;
        
        canvas = self.canvasRef.current;
        self.ctx = canvas.getContext('2d');

        self.width = canvas.parentNode.clientWidth;
        canvas.width = self.width;
        canvas.height = 350 -5; 
        this.height = canvas.height;

        window.addEventListener('resize', () => {
            self.width = canvas.parentNode.clientWidth;
            canvas.width = self.width;
            canvas.height = 350 -5; 
            this.height = canvas.height;
        });
    
        self.ctx.fillStyle = '#fff';
        self.ctx.font = "18px 'bebas'";
    
        this.timer = setInterval(() => {
            loop();
        }, dt);
        //timer = setInterval(loop, 5000);
    
        //return;
        this.maxBalls = (self.width * this.height) / 900;
    
        // const testInterval = setInterval(() => {
        //     if (balls.length >= this.maxBalls) {
        //         clearInterval(testInterval);
        //         return;
        //     }
            
        //     const startX = self.width / 2 + getRandomArbitrary(-25, 25);
        //     balls.push(new Ball(startX, 0, 15, 0.7, 10));
    
        //     gravity = Math.max(0.01, 3 - (balls.length / this.maxBalls * 3));
        // }, 100);
        
        function loop() {
            //Clear window at the begining of every frame
            self.ctx.clearRect(0, 0, self.width, self.height);
            for(var i = 0; i < self.balls.length; i++){
                if(!mouse.isDown || i !== self.balls.length - 1){
                    //physics - calculating the aerodynamic forces to drag
                    // -0.5 * Cd * A * v^2 * rho
                    var fx = -0.5 * drag * density * self.balls[i].area * self.balls[i].velocity.x * self.balls[i].velocity.x * (self.balls[i].velocity.x / Math.abs(self.balls[i].velocity.x));
                    var fy = -0.5 * drag * density * self.balls[i].area * self.balls[i].velocity.y * self.balls[i].velocity.y * (self.balls[i].velocity.y / Math.abs(self.balls[i].velocity.y));
        
                    fx = (isNaN(fx)? 0 : fx);
                    fy = (isNaN(fy)? 0 : fy);
                    //console.log(fx);
                    //Calculating the accleration of the ball
                    //F = ma or a = F/m
                    var ax = fx / self.balls[i].mass;
                    var ay = (ag * self.gravity) + (fy / self.balls[i].mass);
        
                    //Calculating the ball velocity 
                    self.balls[i].velocity.x += ax * fps;
                    self.balls[i].velocity.y += ay * fps;
        
                    //Calculating the position of the ball
                    self.balls[i].position.x += self.balls[i].velocity.x * fps * 100;
                    self.balls[i].position.y += self.balls[i].velocity.y * fps * 100;
                }
                
                //Rendering the ball
                //self.ctx.beginPath();
                
                self.ctx.drawImage(images[self.balls[i].imageIndex], self.balls[i].position.x - self.balls[i].radius, self.balls[i].position.y - self.balls[i].radius, self.balls[i].radius * 2, self.balls[i].radius * 2);
                self.ctx.fillText(self.balls[i].text, self.balls[i].position.x - 7, self.balls[i].position.y + 7);


                //Handling the ball collisions
                collisionBall(self.balls[i]);
                collisionWall(self.balls[i]);	
            }
        }
            
        function collisionWall(ball){
            if(ball.position.x > self.width - ball.radius){
                ball.velocity.x *= ball.e;
                ball.position.x = self.width - ball.radius;
            }
            if(ball.position.y > self.height - ball.radius){
                ball.velocity.y *= ball.e;
                ball.position.y = self.height - ball.radius;
            }
            if(ball.position.x < ball.radius){
                ball.velocity.x *= ball.e;
                ball.position.x = ball.radius;
            }
            if(ball.position.y < ball.radius){
                ball.velocity.y *= ball.e;
                ball.position.y = ball.radius;
            }
        }
        function collisionBall(b1){
            for(var i = 0; i < self.balls.length; i++){
                var b2 = self.balls[i];
                if (b1.position.x !== b2.position.x && b1.position.y !== b2.position.y) {
                    //quick check for potential collisions using AABBs
                    if (b1.position.x + b1.radius + b2.radius > b2.position.x
                        && b1.position.x < b2.position.x + b1.radius + b2.radius
                        && b1.position.y + b1.radius + b2.radius > b2.position.y
                        && b1.position.y < b2.position.y + b1.radius + b2.radius) {
                        
                        //pythagoras 
                        var distX = b1.position.x - b2.position.x;
                        var distY = b1.position.y - b2.position.y;
                        var d = Math.sqrt((distX) * (distX) + (distY) * (distY));
            
                        //checking circle vs circle collision 
                        if (d < b1.radius + b2.radius){
                            var nx = (b2.position.x - b1.position.x) / d;
                            var ny = (b2.position.y - b1.position.y) / d;
                            var p = 2 * (b1.velocity.x * nx + b1.velocity.y * ny - b2.velocity.x * nx - b2.velocity.y * ny) / (b1.mass + b2.mass);
        
                            // calulating the point of collision 
                            var colPointX = ((b1.position.x * b2.radius) + (b2.position.x * b1.radius)) / (b1.radius + b2.radius);
                            var colPointY = ((b1.position.y * b2.radius) + (b2.position.y * b1.radius)) / (b1.radius + b2.radius);
                            
                            //stoping overlap 
                            b1.position.x = colPointX + b1.radius * (b1.position.x - b2.position.x) / d;
                            b1.position.y = colPointY + b1.radius * (b1.position.y - b2.position.y) / d;
                            b2.position.x = colPointX + b2.radius * (b2.position.x - b1.position.x) / d;
                            b2.position.y = colPointY + b2.radius * (b2.position.y - b1.position.y) / d;
        
                            //updating velocity to reflect collision 
                            b1.velocity.x -= p * b1.mass * nx;
                            b1.velocity.y -= p * b1.mass * ny;
                            b2.velocity.x += p * b2.mass * nx;
                            b2.velocity.y += p * b2.mass * ny;
                        }
                    }
                }
            }
        }
    }

    render() {
        return (
            <div className="answer-bucket">
                <div className={this.props.disabled ? "answer-bucket__bucket answer-bucket__bucket--disabled" : "answer-bucket__bucket"}>
                    <canvas ref={this.canvasRef}></canvas>
                </div>
                <span className='answer-bucket__stat'>{this.props.stat}%</span>
            </div>
        );
    }
}