import './UserLogin.scss';
import React, { Component } from 'react';

export default class UserLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            consent: false
        }

        this.firstNameRef = React.createRef();
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleConsentChange = this.handleConsentChange.bind(this);
    }

    handleFirstNameChange(event) {
        this.setState({firstName: event.target.value});
    }

    handleLastNameChange(event) {
        this.setState({lastName: event.target.value});
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handleConsentChange(event) {
        this.setState({consent: !this.state.consent});
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.state.firstName || !this.state.lastName || !this.state.email) {
            return;
        }

        this.props.onSubmit(this.state.firstName, this.state.lastName, this.state.email, this.state.consent);
    }

    componentDidMount() {
        this.firstNameRef.current.focus();
    }

    render() {
        return (
            <div className="user-login mt-3 mt-md-5">
                <p className='user-login__intro mb-5'>
                    Welcome to the TEAM LEWIS interactive voting tool, please register your details below and we will take it from there
                </p>

                <form className='user-login__input-section'>
                    <div className='mb-3'>
                        <label htmlFor="firstName" className="form-label">First Name</label>
                        <input required ref={this.firstNameRef} type="text" id="firstName" className="form-control" value={this.state.firstName} onChange={this.handleFirstNameChange} />
                    </div>
                    <div className='mb-4'>
                        <label htmlFor="lastName" className="form-label">Last Name</label>
                        <input required type="text" id="lastName" className="form-control" value={this.state.lastName} onChange={this.handleLastNameChange} />
                    </div>
                    <div className='mb-4'>
                        <label htmlFor="email" className="form-label">Email</label>
                        <input required type="text" id="email" className="form-control" value={this.state.email} onChange={this.handleEmailChange} />
                    </div>
                    <div className="form-check mb-4">
                        <input type="checkbox" id="consent" className="form-check-input" checked={this.state.consent} value="true" onChange={this.handleConsentChange} />
                        <label htmlFor="consent" className="form-check-label">I would like to opt-in to receive communications from TEAM LEWIS. Our <a className='user-login__terms' target='_blank' rel="nofollow" href="https://www.teamlewis.com/uk/privacy-centre/">privacy policy</a>.</label>
                    </div>
                    <button type="submit" id="submitDetails" className="btn home-submit" onClick={this.handleSubmit}>Submit</button>
                </form>
            </div>
        );
    }
}
