import React, { Component } from 'react';
import AnswerBucket from '../answer-bucket/AnswerBucket';
import './QuestionResult.scss';

// const userState = {
//     wai
// }

export default class QuestionResult extends Component {
    
    render() {
        //console.log('question', this.props.question)
        
        const answerBuckets = this.props.question.answers.map((answer, i) => {
            return (
                <div className='col' key={answer.answer}>
                    <AnswerBucket disabled={this.props.disabled} stat={answer.percent} addBall={this.props.addBallRefs[i]} />
                </div>
            );
        });

        const answers = this.props.question.answers.map(answer => {
            return (
                <div className='col' key={answer.answer}>
                    <p className='question-result__answer'>{answer.answer}</p>
                </div>
            );
        });

        return (
            <div className="question-result mt-3 mt-md-5">
                <p className='question-result__question mb-5'>
                    {this.props.question.question}
                </p>

                <div className='container question-result__choices'>
                    <div className='row'>
                        {answers}
                    </div>
                    <div className='row mb-4'>
                        {answerBuckets}
                    </div>
                </div>


                <button disabled={this.props.disabled} type="button" className='btn btn-pink btn-action' onClick={this.props.onNext}>Next</button>
            </div>
        );
    }
}