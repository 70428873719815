import './EventEnded.scss';



const EventEnded = () => {
  return (
    <div className="event-ended mt-5">
        Thanks for playing.
    </div>
  );
}

export default EventEnded;