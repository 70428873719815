import React, { Component } from 'react';
import QuestionsList from '../questions-list/QuestionsList';
import Header from '../header/Header';
import './QuestionsListRoute.scss';

// const modes = {
//     waitingToStart: 0,
//     eventInProgress: 1,
//     finished: 2
// }

class QuestionsListRoute extends Component {
    constructor(props) {
        super(props);

        this.initialised = false;
        this.connection = null;

        this.state = {
            //mode: modes.waitingToStart,
            questions: []
        };
    }

    componentDidMount() {
        this.initConnection();
    }

    initConnection() {
        if (this.initialised) {
            return;
        }

        this.initialised = true;
        console.log('connecting');
        const signalR = window.signalR;
        const log = console.log;
        const url = window.location.hostname === 'localhost' ? 'https://localhost:44397/eventHub' : '/eventHub';
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(signalR.LogLevel.Information)
            .build();

        const self = this;

        async function start() {
            try {
                await self.connection.start();
                console.log("SignalR Connected.", self.connection, self.connection.connection.connectionId);

                self.connection.invoke('allQuestions');
            } catch (err) {
                console.log(err);
                setTimeout(start, 5000);
            }
        };

        this.connection.on('broadcastQuestionFromAudience', function (question) {
            log("broadcastQuestionFromAudience: " + question);

            self.setState({
                questions: [...self.state.questions, question]
            });
        });

        this.connection.on('broadcastQuestionsFromAudience', function (questions) {
            log("broadcastQuestionsFromAudience: " + questions);

            self.setState({
                questions: questions
            });
        });

        this.connection.onclose(async () => {
            await start();
        });

        start();
    }

    handleClearQuestions = () => {
        this.connection.invoke('clearQuestions');

        this.setState({
            questions: []
        });
    }

    render() {
        return (
            <div className="App questions-list-route">
                <Header />
                <button type="button" className='btn btn-pink questions-list-route__btn-clear' onClick={this.handleClearQuestions}>Clear</button>
                <h2 className='mt-5 mb-2'>Questions from the audience</h2>
                <QuestionsList questions={this.state.questions} />
            </div>
        );
    }
}

export default QuestionsListRoute;
